import { FC, useRef } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Slider, { Settings } from 'react-slick'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { data } from './TestimonialData'
import TestimonialItem from '../../../components/Home/Testimonial/TestimonialItems'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import IconButton from '@mui/material/IconButton'
// import IconArrowBack from '@mui/icons-material/ArrowBack'
// import IconArrowForward from '@mui/icons-material/ArrowForward'

// interface SliderArrowArrow {
//   onClick?: () => void
//   type: 'next' | 'prev'
//   className?: 'string'
// }
// 
// const SliderArrow: FC<SliderArrowArrow> = (props) => {
//   const { onClick, type, className } = props
//   return (
//     <IconButton
//       sx={{
//         backgroundColor: 'background.paper',
//         color: 'primary.main',
//         '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText' },
//         bottom: { xs: '-28px !important', md: '64px !important' },
//         left: 'unset !important',
//         right: type === 'prev' ? '90px !important' : '30px !important',
//         zIndex: 10,
//         boxShadow: 1,
//       }}
//       disableRipple
//       color="inherit"
//       onClick={onClick}
//       className={className}
//     >
//       {type === 'next' ? <IconArrowForward sx={{ fontSize: 22 }} /> : <IconArrowBack sx={{ fontSize: 22 }} />}
//     </IconButton>
//   )
// }

const StyledSlickContainer = styled('div')(() => ({
    position: 'relative',

    '& .slick-list': { marginLeft: '-30px', marginBottom: '24px' },
}))

const None: FC = () => {
    return (<></>);
}

const HomeTestimonial: FC = () => {
    const sliderRef = useRef(null)

    const sliderConfig: Settings = {
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        // prevArrow: <SliderArrow type="prev" />,
        // nextArrow: <SliderArrow type="next" />,
        prevArrow: <None />,
        nextArrow: <None />,
    }

    return (
        <Box id="#testimonial" sx={{ pb: { xs: 6, md: 10 }, backgroundColor: 'background.level1' }}>
            <Container>
                <Grid container spacing={5} >
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="h2"
                            sx={{
                                position: 'relative',
                                fontSize: { xs: 36, md: 46 },
                                mt: 7,
                                mb: 4,
                                lineHeight: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Testimonial What our{' '}
                            <Typography
                                component="mark"
                                sx={{
                                    position: 'relative',
                                    color: 'primary.main',
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                    backgroundColor: 'unset',
                                }}
                            >
                                Clients{' '}
                            </Typography>
                            Say
                        </Typography>
                        <StyledSlickContainer>
                            <Slider ref={sliderRef} {...sliderConfig}>
                                {data.map((item, index) => (
                                    <TestimonialItem key={String(index)} item={item} />
                                ))}
                            </Slider>
                        </StyledSlickContainer>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box
                            sx={{ mt: 10 }}
                            component={"img"}
                            src='/assets/section/section-bg-dark.png'
                            alt='Testimonial img'
                            width={'100%'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HomeTestimonial
