import { CustomFetch } from "../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getSales = async (dateRange: string) => {
    const response = await CustomFetch(
        API_URL + `/amazon/sp-api/sales?dateRange=${encodeURIComponent(dateRange)}`,
        {method: 'GET'}
    );
    return response.json();
}

const DashboardService = {
    getSales,
}

export default DashboardService;