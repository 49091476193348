import type { ColorSystemOptions } from '@mui/material/styles';

import { california, deepBlue, kepple, logoBlue, logoGreen, redOrange, shakespeare, vibrantOrange } from './colors'; // neonBlue, nevada, 
import type { ColorScheme } from './types';

export const colorSchemes = {
  dark: {
    palette: {
      action: { disabledBackground: 'rgba(0, 0, 0, 0.12)' },
      background: {
        default: 'var(--mui-palette-neutral-950)',
        defaultChannel: '9 10 11',
        paper: 'var(--mui-palette-neutral-900)',
        // paper: 'rgba(18, 21, 23, 1)',
        level1: 'var(--mui-palette-neutral-800)',
        level2: 'var(--mui-palette-neutral-700)',
        level3: 'var(--mui-palette-neutral-600)',
      },
      common: { black: '#000000', white: '#ffffff' },
      divider: 'var(--mui-palette-neutral-700)',
      dividerChannel: '50 56 62',
      error: {
        ...redOrange,
        light: redOrange[300],
        main: redOrange[400],
        dark: redOrange[500],
        contrastText: 'var(--mui-palette-common-black)',
      },
      info: {
        ...shakespeare,
        light: shakespeare[300],
        main: shakespeare[400],
        dark: shakespeare[500],
        contrastText: 'var(--mui-palette-common-black)',
      },
      neutral: { ...deepBlue },
      primary: {
        // ...neonBlue,
        // light: neonBlue[400],
        // main: neonBlue[500],
        // dark: neonBlue[600],
        // contrastText: 'var(--mui-palette-common-white)',

        // Last working orange
        // ...vibrantOrange,
        // light: vibrantOrange[400],
        // main: vibrantOrange[500],
        // dark: vibrantOrange[600],
        ...logoBlue,
        light: logoBlue[400],
        main: logoBlue[500],
        dark: logoBlue[600],
        contrastText: 'var(--mui-palette-common-white)', 
      },
      secondary: {
        // ...nevada,
        // light: nevada[600],
        // main: nevada[700],
        // dark: nevada[800],
        // contrastText: 'var(--mui-palette-common-white)',

        // Last working dark blue
        // ...deepBlue,
        // light: deepBlue[300],
        // main: deepBlue[500],
        // dark: deepBlue[700],
        ...logoGreen,
        light: logoGreen[400],
        main: logoGreen[500],
        dark: logoGreen[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
      success: {
        ...kepple,
        light: kepple[300],
        main: kepple[400],
        dark: kepple[500],
        contrastText: 'var(--mui-palette-common-black)',
      },
      text: {
        primary: 'var(--mui-palette-neutral-100)',
        primaryChannel: '240 244 248',
        secondary: 'var(--mui-palette-neutral-400)',
        secondaryChannel: '159 166 173',
        disabled: 'var(--mui-palette-neutral-600)',
      },
      warning: {
        ...california,
        light: california[300],
        main: california[400],
        dark: california[500],
        contrastText: 'var(--mui-palette-common-black)',
      },
    },
  },
  light: {
    palette: {
      action: { disabledBackground: 'rgba(0, 0, 0, 0.06)' },
      background: {
        default: 'var(--mui-palette-common-white)',
        defaultChannel: '255 255 255',
        paper: 'var(--mui-palette-common-white)',
        // paper: 'rgba(255, 255, 255, 1)',
        level1: 'var(--mui-palette-neutral-50)',
        level2: 'var(--mui-palette-neutral-100)',
        level3: 'var(--mui-palette-neutral-200)',
      },
      common: { black: '#000000', white: '#ffffff' },
      divider: 'var(--mui-palette-neutral-200)',
      dividerChannel: '220 223 228',
      error: {
        ...redOrange,
        light: redOrange[400],
        main: redOrange[500],
        dark: redOrange[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
      info: {
        ...shakespeare,
        light: shakespeare[400],
        main: shakespeare[500],
        dark: shakespeare[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
      neutral: { ...deepBlue },
      primary: {
        // ...neonBlue,
        // light: neonBlue[400],
        // main: neonBlue[500],
        // dark: neonBlue[600],
        // contrastText: 'var(--mui-palette-common-white)',

        // Last working orange
        // ...vibrantOrange,
        // light: vibrantOrange[400],
        // main: vibrantOrange[500],
        // dark: vibrantOrange[600],
        ...logoBlue,
        light: logoBlue[400],
        main: logoBlue[500],
        dark: logoBlue[600],
        contrastText: 'var(--mui-palette-common-white)', 
      },
      secondary: {
        // ...nevada,
        // light: nevada[600],
        // main: nevada[700],
        // dark: nevada[800],
        // contrastText: 'var(--mui-palette-common-white)',

        // Last working dark blue
        // ...deepBlue,
        // light: deepBlue[300],
        // main: deepBlue[500],
        // dark: deepBlue[700],
        ...logoGreen,
        light: logoGreen[400],
        main: logoGreen[500],
        dark: logoGreen[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
      success: {
        ...kepple,
        light: kepple[400],
        main: kepple[500],
        dark: kepple[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
      text: {
        primary: 'var(--mui-palette-neutral-900)',
        primaryChannel: '33 38 54',
        secondary: 'var(--mui-palette-neutral-500)',
        secondaryChannel: '102 112 133',
        disabled: 'var(--mui-palette-neutral-400)',
      },
      warning: {
        ...california,
        light: california[400],
        main: california[500],
        dark: california[600],
        contrastText: 'var(--mui-palette-common-white)',
      },
    },
  },
} satisfies Partial<Record<ColorScheme, ColorSystemOptions>>;
// import type { ColorSystemOptions } from '@mui/material/styles';
// import { california, deepBlue, kepple, redOrange, shakespeare, vibrantOrange } from './colors'; 
// import type { ColorScheme } from './types';

// export const colorSchemes = {
//   dark: {
//     palette: {
//       action: { disabledBackground: 'rgba(0, 0, 0, 0.12)' },
//       background: {
//         default: '#121212', // Use a hex value instead of a CSS variable
//         paper: '#1E1E1E',
//         level1: '#2A2A2A',
//         level2: '#3C3C3C',
//         level3: '#4D4D4D',
//       },
//       common: { black: '#000000', white: '#ffffff' },
//       divider: '#3C3C3C',
//       error: {
//         ...redOrange,
//         light: redOrange[300],
//         main: redOrange[400],
//         dark: redOrange[500],
//         contrastText: '#000000',
//       },
//       info: {
//         ...shakespeare,
//         light: shakespeare[300],
//         main: shakespeare[400],
//         dark: shakespeare[500],
//         contrastText: '#000000',
//       },
//       neutral: { ...deepBlue },
//       primary: {
//         ...vibrantOrange,
//         light: vibrantOrange[400],
//         main: vibrantOrange[500],
//         dark: vibrantOrange[600],
//         contrastText: '#000000',
//       },
//       secondary: {
//         ...deepBlue,
//         light: deepBlue[300],
//         main: deepBlue[500],
//         dark: deepBlue[700],
//         contrastText: '#000000',
//       },
//       success: {
//         ...kepple,
//         light: kepple[300],
//         main: kepple[400],
//         dark: kepple[500],
//         contrastText: '#000000',
//       },
//       text: {
//         primary: '#FFFFFF',
//         secondary: '#B0BEC5',
//         disabled: '#757575',
//       },
//       warning: {
//         ...california,
//         light: california[300],
//         main: california[400],
//         dark: california[500],
//         contrastText: '#000000',
//       },
//     },
//   },
//   light: {
//     palette: {
//       action: { disabledBackground: 'rgba(0, 0, 0, 0.06)' },
//       background: {
//         default: '#FFFFFF',
//         paper: '#FFFFFF',
//         level1: '#F5F5F5',
//         level2: '#EEEEEE',
//         level3: '#E0E0E0',
//       },
//       common: { black: '#000000', white: '#ffffff' },
//       divider: '#E0E0E0',
//       error: {
//         ...redOrange,
//         light: redOrange[400],
//         main: redOrange[500],
//         dark: redOrange[600],
//         contrastText: '#FFFFFF',
//       },
//       info: {
//         ...shakespeare,
//         light: shakespeare[400],
//         main: shakespeare[500],
//         dark: shakespeare[600],
//         contrastText: '#FFFFFF',
//       },
//       neutral: { ...deepBlue },
//       primary: {
//         ...vibrantOrange,
//         light: vibrantOrange[400],
//         main: vibrantOrange[500],
//         dark: vibrantOrange[600],
//         contrastText: '#FFFFFF',
//       },
//       secondary: {
//         ...deepBlue,
//         light: deepBlue[300],
//         main: deepBlue[500],
//         dark: deepBlue[700],
//         contrastText: '#FFFFFF',
//       },
//       success: {
//         ...kepple,
//         light: kepple[400],
//         main: kepple[500],
//         dark: kepple[600],
//         contrastText: '#FFFFFF',
//       },
//       text: {
//         primary: '#000000',
//         secondary: '#757575',
//         disabled: '#B0BEC5',
//       },
//       warning: {
//         ...california,
//         light: california[400],
//         main: california[500],
//         dark: california[600],
//         contrastText: '#FFFFFF',
//       },
//     },
//   },
// } satisfies Partial<Record<ColorScheme, ColorSystemOptions>>;
