import { FC } from 'react'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import { Navigation } from '../../../interfaces/Navigation'
import { Navigations as HeaderNavigations } from '../Header/Navigation/NavigationData'
import FooterSectionTitle from './FooterSectionTitle'
import { paths } from '../../../paths'

const FeaturesMenu: Array<Navigation> = [
    { label: 'Order Management', path: '#' },
    { label: 'Shipping Reconciliation', path: '#'},
    { label: 'Deductions Tracking', path: '#' },
    { label: 'Margin Analysis', path: '#' },
]

const PageMenu = HeaderNavigations;

const SupportMenu: Array<Navigation> = [
    { label: 'How to...', path: '#' },
    { label: 'Submit Ticket', path: '#' },
    { label: 'Seller Community', path: '#' },
    { label: 'Request Demo', path: '#' },
]

const CompanyMenu: Array<Navigation> = [
    { label: 'Contact Us', path: '#' },
    { label: 'Privacy Policy', path: paths.legals.privacyPolicy },
    { label: 'Terms of service', path: paths.legals.termsOfService },
    { label: 'FAQ', path: '#' },
]

interface NavigationItemProps {
    label: string
    path: string
}

const NavigationItem: FC<NavigationItemProps> = ({ label, path }) => {
    return (
        // <Box>
        <Link
            href={path}
            underline="hover"
            sx={{
                display: 'block',
                mb: 1,
                color: 'primary.contrastText',
            }}
        >
            {label}
        </Link>
        // </Box>
    )
}

const FooterNavigation: FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <FooterSectionTitle title='Features' />
                {FeaturesMenu.map(({ label, path }, index) => (
                    <NavigationItem key={index + path} label={label} path={path} />
                ))}
            </Grid>
            <Grid item xs={12} md={3}>
                <FooterSectionTitle title='Menu' />
                {PageMenu.map(({ label, path }, index) => (
                    <NavigationItem key={index + path} label={label} path={path} />
                ))}
            </Grid>
            <Grid item xs={12} md={3}>
                <FooterSectionTitle title='Support' />
                {SupportMenu.map(({ label, path }, index) => (
                    <NavigationItem key={index + path} label={label} path={path} />
                ))}
            </Grid>
            <Grid item xs={12} md={3}>
                <FooterSectionTitle title='About' />
                {CompanyMenu.map(({ label, path }, index) => (
                    <NavigationItem key={index + path} label={label} path={path} />
                ))}
            </Grid>
        </Grid>
    )
}

export default FooterNavigation
