import React from 'react'
import MainLayout from '../Layout'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { Box, Container, Grid, Typography } from '@mui/material'
import { paths } from '../../../paths'
import { Link } from 'react-router-dom'

const TermsOfService = () => {
    return (
        <React.Fragment>
            <MainLayout>
                <PageContainer title='Terms of Service | Virtacc' description='This is Terms of Service page of Virtacc'>
                    <Box id="head" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
                        <Container maxWidth="lg">
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        // textAlign: { xs: 'center', md: 'left' },
                                        textAlign: 'left',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ mb: 3 }}>
                                        <Typography
                                            component="h6"
                                            sx={{
                                                position: 'relative',
                                                fontSize: { xs: 30, md: 45 },
                                                letterSpacing: 1.5,
                                                fontWeight: 'bold',
                                                lineHeight: 1.3,
                                            }}
                                        >
                                            Terms of service
                                        </Typography>
                                    </Box>

                                    {/* OVERVIEW */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Overview
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }} >
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Throughout the site, the terms “we”, “us” and “our” refer to Virtacc (a brand of Provizz Consultants, E96 E-block, Kalkaji, 110019, India). Virtacc offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                                            <p>By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
                                            <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                                            <p>Any new features or tools which are added to the site shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
                                            <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                                            <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
                                            <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
                                            <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
                                            <p>Use of our Services requires a Virtacc account. You agree to provide us with complete and accurate information when you register for an account. You will be solely responsible and liable for any activity that occurs under your username. You are responsible for keeping your password secure.</p>
                                        </Typography>
                                    </Box>

                                    {/* GENERAL CONDITIONS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            General Conditions
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                                            <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
                                            <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
                                            <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
                                        </Typography>
                                    </Box>

                                    {/* ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Accuracy, Completeness and Timeliness of Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                                        </Typography>
                                    </Box>

                                    {/* MODIFICATIONS TO THE SERVICE, PRICES, AND SUBSCRIPTION PLANS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Modifications to the Service, Prices, and Subscription Plans
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.</p>
                                            <p>In addition to the above, our service operates on a subscription model with different plan tiers. If you exceed the limits of your current plan, you may be automatically upgraded to a higher plan to accommodate your usage. This automatic upgrade will be subject to the pricing of the higher plan. We will make notify you per E-Mail when an automatic upgrade is due. It is your responsibility to monitor your usage and manage your subscription plan accordingly.</p>
                                        </Typography>
                                    </Box>

                                    {/* PRODUCTS OR SERVICES */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Products or Services
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any service at any time. Any offer for any product or service made on this site is void where prohibited.</p>
                                            <p>We do not warrant that the quality of any services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
                                        </Typography>
                                    </Box>

                                    {/* ACCURACY OF ACCOUNT INFORMATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Accuracy of Account Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>You agree to provide current, complete and accurate account information. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
                                        </Typography>
                                    </Box>

                                    {/* THIRD-PARTY LINKS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Third-Party Links
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
                                            <p>Third-party links on this site may direct you to third-party websites. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
                                            <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                                        </Typography>
                                    </Box>

                                    {/* PERSONAL INFORMATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Personal Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Your submission of personal information through the site is governed by our Privacy Policy. <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={paths.legals.privacyPolicy} >Click here to view our Privacy Policy</Box>.</p>
                                        </Typography>
                                    </Box>

                                    {/* ERRORS, INACCURACIES AND OMISSIONS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Errors, Inaccuracies and Omissions
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
                                            <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
                                        </Typography>
                                    </Box>

                                    {/* PROHIBITED USES */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Prohibited Uses
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                                        </Typography>
                                    </Box>

                                    {/* DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Disclaimer of Warranties; Limitation of Liability
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                                            <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions.</p>
                                            <p>In no case shall Virtacc, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
                                        </Typography>
                                    </Box>

                                    {/* INDEMNIFICATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Indemnification
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>You agree to indemnify, defend and hold harmless Virtacc and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                                        </Typography>
                                    </Box>

                                    {/* SEVERABILITY */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Severability
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                                        </Typography>
                                    </Box>

                                    {/* TERMINATION AND REFUNDS */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Termination and Refunds
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                                            <p>These Terms of Service are effective unless and until terminated by either you or us. The subscription will automatically renew on a monthly basis (or on yearly/half yearly basis, based on the package). You may terminate these Terms of Service at any time by notifying us through the support chat or email that you no longer wish to use our Services or by going to the "Settings" page in the web application, and the effective termination date will be the last day of the then-current renewal term. Virtacc subscription fees are non-refundable.</p>
                                            <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                                        </Typography>
                                    </Box>

                                    {/* ENTIRE AGREEMENT */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Entire Agreement
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
                                            <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
                                            <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
                                        </Typography>
                                    </Box>

                                    {/* GOVERNING LAW */}
                                    {/* <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Governing Law
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                        </Typography>
                                    </Box> */}

                                    {/* CHANGES TO TERMS OF SERVICE */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Changes to Terms of Service
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>You can review the most current version of the Terms of Service at any time at this page.</p>
                                            <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                                        </Typography>
                                    </Box>

                                    {/* CONTACT INFORMATION */}
                                    <Box>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            Contact Information
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ color: 'text.secondary' }}>
                                            <p>Questions about the Terms of Service should be sent to us at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'mailto:support@virtacc.com'} >support@virtacc.com</Box></p>
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>

                        </Container>
                    </Box>
                </PageContainer>
            </MainLayout>
        </React.Fragment>
    )
}

export default TermsOfService