import { FC } from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { SocialLink } from '../../../interfaces/SocialLinks'

export const socialLinks: SocialLink[] = [
    {
        name: 'Instagram',
        link: '#',
        icon: 'assets/images/icons/instagram.svg',
    },
    {
        name: 'YouTube',
        link: '#',
        icon: 'assets/images/icons/youtube.svg',
    },
    {
        name: 'Twitter',
        link: '#',
        icon: 'assets/images/icons/twitter.svg',
    },
    {
        name: 'Dribbble',
        link: '#',
        // link: 'https://dribbble.com/shots/18114471-Coursespace-Online-Course-Landing-Page',
        icon: 'assets/images/icons/dribbble.svg',
    },
    {
        name: 'Github',
        // link: 'https://github.com/hiriski/coursespace-landing-page',
        link: '#',
        icon: 'assets/images/icons/github.svg',
    },
]

interface SocialLinkItemProps {
    item: SocialLink
}

const SocialLinkItem: FC<SocialLinkItemProps> = ({ item }) => (
    <Box
        component="li"
        sx={{
            display: 'inline-block',
            color: 'secondary.contrastText',
            mr: 0.5,
        }}
    >
        <Link
            target="_blank"
            sx={{
                lineHeight: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 36,
                height: 36,
                borderRadius: '50%',
                color: 'inherit',
                '&:hover': {
                    backgroundColor: 'secondary.contrastText',
                },
                '& img': {
                    fill: 'currentColor',
                    width: 22,
                    height: 'auto',
                },
            }}
            href={item.link}
        >
            <img src={item.icon} alt={item.name + 'icon'} />
        </Link>
    </Box>
)

// default
const SocialLinks: FC = () => {
    return (
        <Box sx={{ ml: -1 }}>
            <Box
                component="ul"
                sx={{
                    m: 0,
                    p: 0,
                    lineHeight: 0,
                    borderRadius: 3,
                    listStyle: 'none',
                }}
            >
                {socialLinks.map((item) => {
                    return <SocialLinkItem key={item.name} item={item} />
                })}
            </Box>
        </Box>
    )
}

export default SocialLinks
