import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  description?: string;
  children: ReactNode;
  title?: string;
};

const PageContainer: FC<Props> = ({ title, description, children }) => (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </>
);

export default PageContainer;
