import React from "react";

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';

interface SScatterPlotProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

// Purpose: Compare two continuous variables (e.g., clicks vs spends).
// Data: Plot one metric on the x-axis and another metric on the y-axis.
// Chart Type: Scatter Plot
// Example: Show spends vs clicks for each advertisedSku or campaignId.

const SScatterPlot: React.FC<SScatterPlotProps> = ({ ppcData }) => {

    const chartData = ppcData.reduce((acc, item) => {
        const existing = acc.find((data) => data.spends === item.spend);
        if (existing) {
            // Accumulate the values for existing dates
            existing.spends += item.spend;
        } else {
            // Create a new entry for the date
            acc.push({
                spends: item.spend,
                clicks: item.clicks,
            });
        }
        return acc;
    }, [] as { clicks: number; spends: number; }[]);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <ScatterChart>
                <CartesianGrid />
                <XAxis type="number" dataKey="spends" name="spends" />
                <YAxis type="number" dataKey="clicks" name="clicks" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter name="PPC Data" data={chartData} fill="#8884d8" />
            </ScatterChart>
        </ResponsiveContainer>
    );
};

export default SScatterPlot;