import { FC } from 'react'
import Box from '@mui/material/Box'
import { Link as ScrollLink } from 'react-scroll'
import { Navigations } from './NavigationData'

const Navigation: FC = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            {Navigations.map(({ path, label }) => (
                <Box
                    component={ScrollLink}
                    key={path}
                    activeClass="current"
                    to={path}
                    spy={true}
                    smooth={true}
                    duration={350}
                    sx={{
                        position: 'relative',
                        color: 'text.disabled',
                        cursor: 'pointer',
                        fontWeight: 600,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: { xs: 0, md: 3 },
                        mb: { xs: 3, md: 0 },
                        fontSize: { xs: '1.2rem', md: 'inherit' },
                        ...(path === '/' && {
                            color: 'primary.main',
                        }),

                        '& > div': { display: 'none' },

                        '&.current>div': { display: 'block' },

                        '&:hover': {
                            color: 'primary.main',
                            '&>div': {
                                display: 'block',
                            },
                        },
                    }}
                >
                    {/* <Box
                        sx={{
                            position: 'absolute',
                            top: 12,
                            transform: 'rotate(3deg)',
                            '& img': { width: 44, height: 'auto' },
                        }}
                    > */}
                        {/* eslint-disable-next-line */}
                        {/* <img src="/assets/images/headline-curve.svg" alt="Headline curve" />
                    </Box> */}
                    {label}
                </Box>
            ))}
        </Box>
    )
}

export default Navigation
