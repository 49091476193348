// import React, { useEffect, useState } from 'react';
// import { Fab } from '@mui/material';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// // import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import { styled } from '@mui/system';

// const ScrollToTopButton = styled(Fab)(({ theme }) => ({
//   position: 'fixed',
//   bottom: theme.spacing(2),
//   right: theme.spacing(2),
//   display: 'none',
//   transition: 'display 0.3s ease-in-out',
//   verticalAlign: 'center'
// }));

// const ScrollToTop: React.FC = () => {
//   const [showButton, setShowButton] = useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 300) {
//       setShowButton(true);
//     } else {
//       setShowButton(false);
//     }
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <ScrollToTopButton
//       onClick={scrollToTop}
//       color="primary"
//       style={{ display: showButton ? 'flex' : 'none' }}
//     >
//       <KeyboardArrowUpIcon />
//       {/* <ArrowUpwardIcon /> */}
//     </ScrollToTopButton>
//   );
// };

// export default ScrollToTop;

import React, { useEffect, useState } from 'react';
import { Fab, FabProps } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/system';

interface ScrollToTopButtonProps extends FabProps {
  show: boolean;
}

const ScrollToTopButton = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'show',
})<ScrollToTopButtonProps>(({ theme, show }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  opacity: show ? 1 : 0,
  transform: show ? 'translateY(0)' : 'translateY(20px)',
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  visibility: show ? 'visible' : 'hidden',
}));

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    setShowButton(window.scrollY > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollToTopButton
      onClick={scrollToTop}
      color="primary"
      show={showButton}
    >
      <KeyboardArrowUpIcon />
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
