import * as React from 'react';
// import type { Metadata } from 'next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import { Button, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import SubscriptionsTable from '../../../components/Organization/Subscriptions/SubscriptionsTable';
import { SubscriptionsType } from '../../../interfaces/Subscriptions';
import subscriptionService from '../api/Subscriptions.api';
import AddSubscriptionForm from '../../../components/Organization/Subscriptions/AddSubscriptonForm';

const SubscriptionsOrganizations: React.FC = () => {
    const [subscriptions, setSubscriptions] = React.useState<SubscriptionsType[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const getSubscriptions = async () => {
        let subscriptionsData = await subscriptionService.getSubscriptions();
        if (subscriptionsData) setSubscriptions(subscriptionsData);
    }
    React.useEffect(() => {
        getSubscriptions();
    }, [])
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);
    const handleFormSuccess = () => {
        handleModalClose(); // Close the modal
        getSubscriptions();  // Refresh the organization data
    };
    const styleModal = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid var(--mui-palette-primary-main)',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
    };
    const renderModal = (
        <Modal
            open={open}
            onClose={handleModalClose}
            aria-labelledby="modal-add-organization"
            aria-describedby="modal-add-organization-description"
        >
            <Stack sx={styleModal}>
                <Stack direction="row" spacing={3}>
                    <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                        <Typography sx={{ mb: 3 }} variant="h5">Add Subscription</Typography>
                    </Stack>
                    <IconButton
                        aria-label="close"
                        onClick={handleModalClose}
                        sx={{ position: 'absolute', right: 20 }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <AddSubscriptionForm onSuccess={handleFormSuccess} />
            </Stack>
        </Modal>
    );
    return (
        <PageContainer title={'Subscriptions | Dashboard | ' + config.site.name} description='This is Subscriptions page of Virtacc'>
            <Layout>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <Stack sx={{ flex: '1 1 auto' }} spacing={1}>
                            <Typography variant="h4">Subscriptions</Typography>
                        </Stack>
                        <Button onClick={handleModalOpen} startIcon={<AddIcon />} variant="contained">
                            Add
                        </Button>
                        {renderModal}
                    </Stack>
                    <SubscriptionsTable
                        subscriptions={subscriptions}
                        sx={{ height: '100%' }}
                    />
                </Stack>
            </Layout>
        </PageContainer>
    );
}

export default SubscriptionsOrganizations
