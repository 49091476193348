import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';

import { paths } from '../../../paths';
import { Helper } from '../../../utils/Helper';
import { toast } from 'react-toastify';
import { UserPayload } from '../../../interfaces/UserPayload';

export interface UserPopoverProps {
    anchorEl: Element | null;
    onClose: () => void;
    open: boolean;
}

const UserPopOver: React.FC<UserPopoverProps> = ({ anchorEl, onClose, open }) => {

    const [User, setUser] = React.useState<UserPayload>({ email: '', role: '0', organization: '' });
    React.useEffect(() => {
        const User = Helper.getCurrentUser();
        setUser(User);
    }, []);

    const handleSignOut = React.useCallback(async (): Promise<void> => {
        try {
            Helper.removeStorageData('user');
            Helper.removeStorageData('userToken');
            window.location.reload();
            toast.success('User logged out successfully');
        } catch (err) {
            toast.error('Something went wrong! Please try again.');
        }
    }, []);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClose={onClose}
            open={open}
            slotProps={{ paper: { sx: { width: '240px' } } }}
        >
            <Box sx={{ p: '16px 20px ' }}>
                <Typography variant="subtitle1">{User.email}</Typography>
                <Typography color="text.secondary" variant="body2">
                    {User.email}
                </Typography>
            </Box>
            <Divider />
            <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
                <MenuItem component={RouterLink} to={paths.organization.settings} onClick={onClose}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem component={RouterLink} to={paths.organization.account} onClick={onClose}>
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    Sign out
                </MenuItem>
            </MenuList>
        </Popover>
    );
}

export default UserPopOver;