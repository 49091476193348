// Dashboard.tsx
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "./Layout";
import React from "react";
import PageContainer from "../../components/PageContainer/PageContainer";
import { config } from "../../config";
import ExDashboard from "../../components/Dashboard/Overview/Thiss";
import DashboardService from "./api/Dashboard.api";
import { ExSales } from "../../components/Dashboard/Overview/ExSales";
import { SalesType } from "../../interfaces/Amazon/ExSales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import Chart1 from "../../components/Dashboard/Overview/Chart1";
import { ppcType } from "../../interfaces/PPC";
import { OrdersType } from "../../interfaces/Orders";
import OrdersService from "./Orders/api/Orders.api";
import PPCService from "./PPC/api/PPC.api";
import PPCChart01 from "../../components/Dashboard/Overview/PPCChart01";
import PPCChart02 from "../../components/Dashboard/Overview/PPCChart02";

const Dashboard: React.FC = () => {
  // Helper functions
  const formatDateRange = (range: [Dayjs | null, Dayjs | null]): string => {
    if (!range[0] || !range[1]) return '';
    return `${dayjs(range[0]).format('YYYY-MM-DDTHH:mmZ')}--${dayjs(range[1]).format('YYYY-MM-DDTHH:mmZ')}`;
  };

  const calculateLastMonthDateRange = (currentRange: [Dayjs | null, Dayjs | null]): [Dayjs | null, Dayjs | null] => {
    return [
      currentRange[0] ? currentRange[0].subtract(1, 'month') : null,
      currentRange[1] ? currentRange[1].subtract(1, 'month') : null,
    ];
  };

  // State initialization
  const [dateRange, setDateRange] = React.useState<[Dayjs | null, Dayjs | null]>([
    dayjs().startOf('month'),
    dayjs().endOf('day'),
  ]);

  const [lastMonthDateRange, setLastMonthDateRange] = React.useState<[Dayjs | null, Dayjs | null]>(
    calculateLastMonthDateRange(dateRange)
  );

  const [salesData, setSalesData] = React.useState<SalesType | undefined>(undefined);
  const [lastMonthSalesData, setLastMonthSalesData] = React.useState<SalesType | undefined>(undefined);

  // for charts
  const [PPCData, setPPCData] = React.useState<ppcType[]>([]);
  const [ordersData, setOrdersData] = React.useState<OrdersType[]>([]);

  const fetchOrdersFromDB = async () => {
    let orders = await OrdersService.getAllOrders();
    if (orders.data) setOrdersData(orders.data);
  };
  const fetchPPCFromDB = async () => {
    let ppcFromDb = await PPCService.getPPCDataFromDB();
    if (ppcFromDb.data) setPPCData(ppcFromDb.data);
  }

  React.useEffect(() => {
    fetchOrdersFromDB();
    fetchPPCFromDB();
  }, [])

  React.useEffect(() => {
    fetchSalesData();
    fetchLastMonthSalesData();
  }, [dateRange]);

  const fetchSalesData = async () => {
    const salesData = await DashboardService.getSales(formatDateRange(dateRange));
    if (salesData.data) setSalesData(salesData.data[0]);
  };

  const fetchLastMonthSalesData = async () => {
    const lastMonthData = await DashboardService.getSales(formatDateRange(lastMonthDateRange));
    if (lastMonthData.data) setLastMonthSalesData(lastMonthData.data[0]);
  };

  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    setDateRange(newValue);
    setLastMonthDateRange(calculateLastMonthDateRange(newValue));
  };

  const lastAmount = lastMonthSalesData?.totalSales?.amount;
  const currentAmount = salesData?.totalSales?.amount;

  const calculatePercentageChange = () => {
    if (lastAmount && currentAmount) {
      return ((currentAmount - lastAmount) / lastAmount) * 100;
    }
    return null; // Return null or a default value if data is missing
  };

  const percentageChange = calculatePercentageChange();
  const difference = percentageChange ? percentageChange.toFixed(2) : '0';
  const trend = parseInt(difference) < 0 ? 'down' : 'up';

  return (
    <PageContainer title={`Overview | Dashboard | ${config.site.name}`} description="This is the Overview page of Virtacc">
      <Layout>
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <Typography variant="h4" sx={{ flex: 1 }}>Overview</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{ start: "From Date", end: "To Date" }}
                value={dateRange}
                onChange={handleDateRangeChange}
              />
            </LocalizationProvider>
          </Stack>
          <Grid container spacing={3}>
            <Grid lg={6} sm={6} xs={12}>
              <ExSales
                diff={difference}
                trend={trend} sx={{ height: '100%' }}
                interval={dateRange}
                data={salesData}
              />
            </Grid>
            <Grid lg={6} sm={6} xs={12}>
              <ExSales sx={{ height: '100%' }} interval={lastMonthDateRange} data={lastMonthSalesData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <ExDashboard />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              {/* <Chart1 ppcData={PPCData} ordersData={ordersData} dateRange={dateRange}/> */}
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <PPCChart01 ppcData={PPCData} ordersData={ordersData} dateRange={dateRange} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <PPCChart02 ppcData={PPCData} ordersData={ordersData} dateRange={dateRange} />
            </Grid>
          </Grid>
        </Stack>
      </Layout>
    </PageContainer>
  );
};

export default Dashboard;
