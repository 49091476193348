import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import { IntegrationData } from '../../../../interfaces/Integration';

export interface IntegrationDataProps {
  sx?: SxProps;
  channels?: IntegrationData[];
}

function changeStatus() {
  // console.log('clicked');
}

const ChannelsTable: React.FC<IntegrationDataProps> = ({ channels = [], sx }) => {
  return (
    <Card sx={sx}>
      <CardHeader title="Channels" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Channel</TableCell>
              <TableCell>API Type</TableCell>
              {/* <TableCell>Access Token</TableCell> */}
              {/* <TableCell>Refresh Token</TableCell> */}
              {/* <TableCell>Client ID</TableCell> */}
              <TableCell>Date Added</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.length > 0 ? (
              channels.map((row) => (
                <TableRow hover key={row.organization}>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.api_type}</TableCell>
                  {/* <TableCell>{row.access_token}</TableCell> */}
                  {/* <TableCell>{row.refresh_token}</TableCell> */}
                  {/* <TableCell>{row.client_id}</TableCell> */}
                  <TableCell>{dayjs(row.createdAt).format('MMM D, YYYY')}</TableCell>
                  <TableCell>
                    <Chip component={Button} onClick={changeStatus} color='success' label='Active' />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={<ArrowForwardIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
      {/* <TablePagination 
        component="div"
        count={count}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
}

export default ChannelsTable;