import DataTable from "./dynamicTableColumnData";

const data = [
    { id: 1, name: 'John Doe', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.', date: '2024-10-12' },
    { id: 2, name: 'Jane Smith', description: 'Proin tristique nisl nec nulla faucibus, eget convallis libero ullamcorper. Sed ac tortor nec odio ullamcorper tempus.', date: '2024-11-01' },
    { id: 3, name: 'Alice Johnson', description: 'Aliquam erat volutpat. Nunc et lectus eget dui malesuada pharetra. Nulla facilisi. Morbi at nisl sit amet leo iaculis pharetra.', date: '2024-11-10' },
];

const DynamicDataTable: React.FC = () => {

    return (
        <DataTable data={data} />
    )

};

export default DynamicDataTable