import React, { useMemo, useState } from "react";
import { Card, CardContent, Typography, Grid, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import { ppcType } from "../../../../interfaces/PPC";

interface Chart01Props {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const Chart01: React.FC<Chart01Props> = ({ ppcData }) => {
    // Set default date and selected data
    const [selectedSKU, setSelectedSKU] = useState<string>('');
    const [metrics, setMetrics] = useState({
        totalSpends: 0,
        totalClicks: 0,
        totalImpressions: 0,
        ACoS: 0
    });

    // Update the metrics when a new date is selected
    const handleSKUChange = (event: SelectChangeEvent<string>) => {
        const sku = event.target.value;
        setSelectedSKU(sku);

        // Find data for the selected date
        const selectedData = ppcData.filter(item => 
            item.advertisedSku === sku &&
            item.spend != null && item.clicks != null &&
            item.impressions != null && item.acosClicks14d != null
        );
        if (selectedData.length > 0) {
            // Calculate aggregated metrics
            const totalSpends = selectedData.reduce((acc, item) => acc + item.spend, 0);
            const totalClicks = selectedData.reduce((acc, item) => acc + item.clicks, 0);
            const totalImpressions = selectedData.reduce((acc, item) => acc + item.impressions, 0);
            const totalACoS = selectedData.reduce((acc, item) => acc + item.acosClicks7d, 0); // Aggregate ACoS over all entries

            setMetrics({
                totalSpends,
                totalClicks,
                totalImpressions,
                ACoS: totalACoS / selectedData.length // Average ACoS across selected data
            });
        }
    };

    // Collect the list of available dates
    const availableSKUs = Array.from(new Set(ppcData.map(item => item.advertisedSku)));

    return (
        <div>
            {/* Date selection dropdown */}
            <FormControl fullWidth margin="normal">
                <InputLabel>Choose SKU</InputLabel>
                <Select
                    value={selectedSKU}
                    onChange={handleSKUChange}
                    label="Choose SKU"
                >
                    {availableSKUs.map(sku => (
                        <MenuItem key={sku} value={sku}>
                            {sku}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Displaying KPI Metrics */}
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Spends</Typography>
                            <Typography variant="h4">Rs. {metrics.totalSpends}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Clicks</Typography>
                            <Typography variant="h4">{metrics.totalClicks}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Total Impressions</Typography>
                            <Typography variant="h4">{metrics.totalImpressions}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">ACoS</Typography>
                            <Typography variant="h4">{metrics.ACoS}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Chart01;
