import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HubIcon from '@mui/icons-material/Hub';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';

export const navIcons = {
    'donut-large': DonutLargeIcon,
    'analytics': AnalyticsOutlinedIcon,
    'settings': SettingsIcon,
    'people-outline': PeopleOutlineIcon,
    'manage-account': ManageAccountsIcon,
    'hub': HubIcon,
    'error': ErrorIcon,
    'shopping-bag': ShoppingBagOutlinedIcon,
    'shopping-cart': ShoppingCartOutlinedIcon,
    'credit-card': CreditCardIcon,
    'download': FileDownloadOutlinedIcon,
    'currency-rupee': CurrencyRupeeIcon,
    'file-log': DescriptionOutlinedIcon,
    'recommend': RecommendOutlinedIcon,
    'inventory': Inventory2OutlinedIcon,
    'notepad': EditNoteOutlinedIcon,
    'shipment': LocalShippingOutlinedIcon,
    'person-connect': ConnectWithoutContactOutlinedIcon,
    'email': EmailOutlinedIcon,
    'campaign': CampaignOutlinedIcon,
    'sync': SyncOutlinedIcon,
    'cancel-circle': CancelOutlinedIcon,
    'undo': UndoOutlinedIcon,
    'change-circle': ChangeCircleOutlinedIcon,
    'alert-add': NotificationImportantOutlinedIcon,
    'person-add': PersonAddOutlinedIcon,
    'payment-bill': PaymentsOutlinedIcon,
    'tuning': TuneOutlinedIcon,
    'auto-fix': AutoFixHighOutlinedIcon,
    'pie-chart': PieChartOutlinedIcon,
} as Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">> >