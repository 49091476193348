import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';

type TableRowData = {
    id: number;
    name: string;
    description: string;
    date: string;
};

type TableProps = {
    data: TableRowData[];
};

// Helper function to truncate text
const truncateText = (text: string, maxLength: number = 20): string => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};
const DataTable: React.FC<TableProps> = ({ data }) => {
    const [expandedRow, setExpandedRow] = useState<number | null>(null); // Track the row that is expanded

    const handleRowClick = (id: number) => {
        // Toggle the expanded state of the clicked row
        setExpandedRow((prevRow) => (prevRow === id ? null : id));
    };

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    <Tooltip title={row.description} placement="top">
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            onClick={() => handleRowClick(row.id)}>
                                            {/* If this row is expanded, show the full description; otherwise, show truncated text */}
                                            {expandedRow === row.id
                                                ? row.description
                                                : truncateText(row.description, 20)}
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default DataTable;
