import { Navigate } from "react-router-dom";
import { ReactElement } from "react";
import { isAuthenticated } from "../../utils/Auth/Auth";
import { paths } from "../../paths";

interface ProtectedRouteProps {
  children: ReactElement;
}

export const ProtectedRoute = ( {children} : ProtectedRouteProps) => {
  if (!isAuthenticated()) {
    // user is not authenticated
    return <Navigate to={paths.auth.signIn} />;
  }
  return children;
};