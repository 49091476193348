import { FC } from 'react'
import Button from '@mui/material/Button'
import { paths } from '../../../../paths'
import { Stack } from '@mui/material'

const AuthNavigation: FC = () => {
    const signIN = () => window.location.href = paths.auth.signIn
    const signUP = () => window.location.href = paths.auth.signUp
    return (
        <Stack direction={'row'} spacing={2} sx={{ '& button:first-child': { mr: 2 } }}>
            <Button onClick={signIN} variant="outlined">
                Sign In
            </Button>
            <Button onClick={signUP} variant='contained'>
                Sign Up
            </Button>
        </Stack>
    )
}

export default AuthNavigation
