'use client';

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import { createTheme } from './create-theme';
import { GlobalStyles } from '@mui/material';

const globalStyles = (
    <GlobalStyles
        styles={{
            '::selection': {
                color: 'var(--mui-palette-common-white)',
                backgroundColor: 'var(--mui-palette-primary-main)',
            },
            body: {
                /* Custom class for scrollable divs */
                '::-webkit-scrollbar': {
                    width: '6px',
                },

                '::-webkit-scrollbar-track': {
                    backgroundColor: 'var(--mui-palette-common-white)',
                },

                '::-webkit-scrollbar-thumb': {
                    background: 'var(--mui-palette-primary-main)',
                    borderRadius: '1px',
                },

                '::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: 'var(--mui-palette-primary-main)',
                },
            }
        }}
    />
);

export interface ThemeProviderProps {
    children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
    const theme = createTheme();
    return (
        <CssVarsProvider theme={theme}>
            <CssBaseline />
            {globalStyles}
            {children}
        </CssVarsProvider>
    )
}
export default ThemeProvider