import { Card, CardContent, Input } from '@mui/material';
import React from 'react';

interface MarketplaceData {
    channel: string;
    totalSales: number;
    totalRevenue: number;
    adjustments: number;
    netRevenue: number;
    paymentStatus: string;
    lastUpdate: string;
}

const marketplaceData: MarketplaceData[] = [
    {
        channel: 'Amazon',
        totalSales: 150,
        totalRevenue: 10000,
        adjustments: -500,
        netRevenue: 9500,
        paymentStatus: 'Completed',
        lastUpdate: '2024-10-20',
    },
    {
        channel: 'eBay',
        totalSales: 100,
        totalRevenue: 5000,
        adjustments: -200,
        netRevenue: 4800,
        paymentStatus: 'Pending',
        lastUpdate: '2024-10-21',
    },
    {
        channel: 'Shopify',
        totalSales: 80,
        totalRevenue: 8000,
        adjustments: -300,
        netRevenue: 7700,
        paymentStatus: 'Completed',
        lastUpdate: '2024-10-22',
    },
];

const ExDashboard: React.FC = () => {
    return (
        <Card sx={{ height: '100%'}}>
            <CardContent>
                <div >
                    <h1>Sales Dashboard {'{Dummy}'}</h1>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Channel</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Total Sales</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Total Revenue</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Adjustments</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Net Revenue</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Payment Status</th>
                                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Last Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {marketplaceData.map((data, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.channel}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.totalSales}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>${data.totalRevenue.toFixed(2)}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>${data.adjustments.toFixed(2)}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>${data.netRevenue.toFixed(2)}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.paymentStatus}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.lastUpdate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </Card>

    );
};

export default ExDashboard;
