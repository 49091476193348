import React, { useState, useEffect } from 'react';
import {
    Card, CardHeader, Button, Divider, Box, Table, TableHead, TableRow, TableCell,
    TableBody, Chip, TablePagination, ChipProps, SxProps, OutlinedInput, InputAdornment,
    Stack
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { ppcType } from '../../../interfaces/PPC';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const API_URL = process.env.REACT_APP_API_URL;

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
    PAUSED: { label: 'Inactive', color: 'default' },
    ENABLED: { label: 'Active', color: 'success' },
};

export interface PPCProps {
    sx?: SxProps;
    ppcData?: ppcType[];
}

const PPCDataTable: React.FC<PPCProps> = ({ ppcData = [], sx }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredPPCData, setFilteredPPCData] = useState<ppcType[]>(ppcData);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
        dayjs().startOf('month'),
        dayjs().endOf('day')
    ]);

    // Sorting function for date
    const sortDataByDate = (data: ppcType[], order: 'asc' | 'desc') => {
        return data.sort((a, b) => {
            const dateA = dayjs(a.date).unix();
            const dateB = dayjs(b.date).unix();
            return order === 'asc' ? dateA - dateB : dateB - dateA;
        });
    };

    // Filter and sort data
    const filterPPCData = () => {
        let filtered = ppcData;
        // You can add more filtering logic here if needed
        filtered = sortDataByDate(filtered, 'asc'); // Sort by date ascending
        setFilteredPPCData(filtered);
    };

    // Update filtered data whenever ppcData changes
    useEffect(() => {
        filterPPCData();
    }, [ppcData]);

    // Handle Pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = async () => {
        try {
            toast.info('Clicked on Download Button')
            // Add your download logic here
        } catch (err) {
            toast.error('Something went wrong! Please try again.');
        }
    };

    const handleFilterChange = (filterValue: string) => {
        const filtered = ppcData.filter(ppc =>
            ppc.advertisedSku.includes(filterValue) ||
            ppc.advertisedAsin.includes(filterValue) ||
            ppc.campaignId.toString() === filterValue ||
            ppc.date.includes(filterValue)
        );
        setFilteredPPCData(filtered);
    };

    const paginatedPPCData = filteredPPCData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Stack direction="row">
                <Card sx={{ flex: '1 1 auto', p: 2 }}>
                    <OutlinedInput
                        defaultValue=""
                        fullWidth
                        placeholder="Search by SKU, ASIN, Campaign Id, Date (YYYY-MM-DD)"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        onChange={(e) => handleFilterChange(e.target.value)}
                    />
                </Card>
                <Card sx={{ p: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            // sx={{ width: "50%"}}
                            localeText={{ start: "From Date", end: "To Date" }}
                            value={dateRange}
                            onChange={(newValue) => setDateRange(newValue)}
                        />
                    </LocalizationProvider>
                </Card>
            </Stack>

            <Card sx={sx}>
                <CardHeader
                    action={
                        <>
                            <Button color="inherit" size="small" startIcon={<SyncIcon />}>
                                Sync
                            </Button>
                            <Button variant='contained' size="small" onClick={handleDownload}>
                                Download
                            </Button>
                        </>
                    }
                />
                <Divider />
                <Box sx={{ overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Campaign Id</TableCell>
                                <TableCell>Campaign Status</TableCell>
                                <TableCell>ROAS Clicks 7d</TableCell>
                                <TableCell>CTR</TableCell>
                                <TableCell>Spend</TableCell>
                                <TableCell>Sales 30d</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>ASIN</TableCell>
                                <TableCell>ACOS Clicks 7d</TableCell>
                                <TableCell>Impressions</TableCell>
                                <TableCell>Clicks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedPPCData.length > 0 ? (
                                paginatedPPCData.map((ppc, index) => {
                                    const { label, color } = statusMap[ppc.campaignStatus] ?? { label: 'Unknown', color: 'default' };
                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell>{dayjs(ppc.date).format('MMM D, YYYY')}</TableCell>
                                            <TableCell>{ppc.campaignId}</TableCell>
                                            <TableCell><Chip color={color} label={label} /></TableCell>
                                            <TableCell>{ppc.roasClicks7d}</TableCell>
                                            <TableCell>{ppc.clickThroughRate}</TableCell>
                                            <TableCell>{ppc.spend}</TableCell>
                                            <TableCell>{ppc.sales30d}</TableCell>
                                            <TableCell>{ppc.advertisedSku}</TableCell>
                                            <TableCell>{ppc.advertisedAsin}</TableCell>
                                            <TableCell>{ppc.acosClicks7d}</TableCell>
                                            <TableCell>{ppc.impressions}</TableCell>
                                            <TableCell>{ppc.clicks}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={12} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
                <Divider />
                <TablePagination
                    component="div"
                    count={filteredPPCData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                />
            </Card>
        </>
    );
};

export default PPCDataTable;