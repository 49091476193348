import React from 'react'
import MainLayout from '../Home/Layout'
import PageContainer from '../../components/PageContainer/PageContainer'
import { Avatar, Box, Card, CardContent, CardHeader, Container, Grid, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { paths } from '../../paths'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const userTestimonials = [
    {
        avatar: <Avatar alt="Remy Sharp" src="/assets/marketing/1.jpg" />,
        name: 'Remy Sharp',
        occupation: 'Senior Engineer',
        testimonial:
            "I absolutely love how versatile this product is! Whether I'm tackling work projects or indulging in my favorite hobbies, it seamlessly adapts to my changing needs. Its intuitive design has truly enhanced my daily routine, making tasks more efficient and enjoyable.",
    },
    {
        avatar: <Avatar alt="Travis Howard" src="/assets/marketing/2.jpg" />,
        name: 'Travis Howard',
        occupation: 'Lead Product Designer',
        testimonial:
            "One of the standout features of this product is the exceptional customer support. In my experience, the team behind this product has been quick to respond and incredibly helpful. It's reassuring to know that they stand firmly behind their product.",
    },
    {
        avatar: <Avatar alt="Cindy Baker" src="/assets/marketing/3.jpg" />,
        name: 'Cindy Baker',
        occupation: 'CTO',
        testimonial:
            'The level of simplicity and user-friendliness in this product has significantly simplified my life. I appreciate the creators for delivering a solution that not only meets but exceeds user expectations.',
    },
    {
        avatar: <Avatar alt="Remy Sharp" src="/assets/marketing/4.jpg" />,
        name: 'Julia Stewart',
        occupation: 'Senior Engineer',
        testimonial:
            "I appreciate the attention to detail in the design of this product. The small touches make a big difference, and it's evident that the creators focused on delivering a premium experience.",
    },
    {
        avatar: <Avatar alt="Travis Howard" src="/assets/marketing/5.jpg" />,
        name: 'John Smith',
        occupation: 'Product Designer',
        testimonial:
            "I've tried other similar products, but this one stands out for its innovative features. It's clear that the makers put a lot of thought into creating a solution that truly addresses user needs.",
    },
    {
        avatar: <Avatar alt="Cindy Baker" src="/assets/marketing/6.jpg" />,
        name: 'Daniel Wolf',
        occupation: 'CDO',
        testimonial:
            "The quality of this product exceeded my expectations. It's durable, well-designed, and built to last. Definitely worth the investment!",
    },
];

const whiteLogos = [
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
    'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
    width: '64px',
    opacity: 0.3,
};

const Support = () => {
    const theme = useTheme();
    const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;
    return (
        <MainLayout>
            <PageContainer title='Support | Virtacc' description='This is Support page of Virtacc'>
                <Box id="#support" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
                    <Container >

                        <Box sx={{ width: '100%', mb: 3 }}>
                            <Typography
                                component="h6"
                                sx={{
                                    position: 'relative',
                                    fontSize: { xs: 30, md: 45 },
                                    letterSpacing: 1.5,
                                    fontWeight: 'bold',
                                    lineHeight: 1.3,
                                }}
                            >
                                Support
                            </Typography>
                        </Box>

                        <Grid container spacing={5}>
                            {/* <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', md: 'unset' }, mb: 4 }}> */}
                            <Grid item xs={12} md={6}>
                                <Typography
                                    component="h2"
                                    sx={{
                                        position: 'relative',
                                        fontSize: { xs: 36, md: 46 },
                                        mt: 7,
                                        mb: 4,
                                        lineHeight: 1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Maintain, Sustain, &{' '}
                                    <Typography
                                        component="mark"
                                        sx={{
                                            position: 'relative',
                                            color: 'primary.main',
                                            fontSize: 'inherit',
                                            fontWeight: 'inherit',
                                            backgroundColor: 'unset',
                                        }}
                                    >
                                        Optimize{' '}
                                    </Typography>
                                    Your Business
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    <p>Your success is a top priority at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={paths.home}>VIRTACC</Box> and we ensure that our customers receive the maximum return on their investment.</p>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
                                <Box sx={{ lineHeight: 0 }} >
                                    <img src="/assets/section/section-bg-dark.png" width={"100%"} alt="Hero img" />
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid container spacing={5} >

                            <Grid item >
                                <Typography
                                    component="h2"
                                    sx={{
                                        position: 'relative',
                                        fontSize: { xs: 36, md: 46 },
                                        mt: 7,
                                        mb: 4,
                                        lineHeight: 1,
                                        fontWeight: 'bold',
                                    }}
                                    textAlign={'center'}
                                >
                                    <Typography
                                        component="mark"
                                        sx={{
                                            position: 'relative',
                                            color: 'primary.main',
                                            fontSize: 'inherit',
                                            fontWeight: 'inherit',
                                            backgroundColor: 'unset',
                                        }}
                                    >
                                        Advanced{' '}
                                    </Typography>
                                    Customer Support
                                </Typography>
                                <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
                                    <p>Each option provides specific service levels and capabilities, from online ticket submissions to chat and phone support, to managed services and onsite visits that help minimize the gap between your optimal solution and your actual solution.</p>
                                </Typography>
                                <Box
                                    sx={{
                                        pt: { xs: 4, sm: 12 },
                                        pb: { xs: 8, sm: 16 },
                                        position: 'relative',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: { xs: 3, sm: 6 },
                                    }}
                                >
                                    <Grid
                                        spacing={3}
                                        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <PhoneOutlinedIcon fontSize='large' />
                                            <p>Call</p>
                                            <p>9876543210</p>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <ForumOutlinedIcon fontSize='large' />
                                            <p>Chat</p>
                                            <p>24x7</p>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <ConfirmationNumberOutlinedIcon fontSize='large' />
                                            <p>Ticket</p>
                                            <p><Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'#'}>Submit a Ticket</Box></p>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-evenly',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <MailOutlineIcon fontSize='large' />
                                            <p>Email</p>
                                            <p><Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'mailto:support@virtacc.com'}>support@virtacc.com</Box></p>
                                        </Box>
                                    </Grid>

                                </Box>

                            </Grid>

                        </Grid>



                    </Container>
                    <Grid container spacing={2}>
                        {userTestimonials.map((testimonial, index) => (
                            <Grid xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        flexGrow: 1,
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            {testimonial.testimonial}
                                        </Typography>
                                    </CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <CardHeader
                                            avatar={testimonial.avatar}
                                            title={testimonial.name}
                                            subheader={testimonial.occupation}
                                        />
                                        <img
                                            src={logos[index]}
                                            alt={`Logo ${index + 1}`}
                                            style={logoStyle}
                                        />
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </PageContainer>
        </MainLayout>
    )
}

export default Support