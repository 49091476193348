'use client';

import * as React from 'react';
import { 
    Button,
    Card, 
    Stack, 
    TextField, 
    Typography, 
} from '@mui/material';
import Layout from './Layout';

const ResetPasswordForm: React.FC = () => {

  const [email, setEmail] = React.useState<string>('');

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }


  return (
    <Layout>
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
                sx={{
                    p: 5,
                    width: 1,
                    maxWidth: 420,
                }}
            >
                <Typography variant="h5">Reset Password</Typography>
                <Typography sx={{ mt: 2, mb: 5 }}></Typography>
                <form onSubmit={handleSubmit} >
                    <Stack spacing={3}>
                        <TextField 
                            name="email" 
                            type="email"
                            label="Email address" 
                            value={email}
                            onChange={onChangeEmail}
                            required
                        />
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            Send recovery link
                        </Button>
                    </Stack>
                </form>
            </Card>
        </Stack>
    </Layout>
  );
}

export default ResetPasswordForm;