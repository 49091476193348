import React from 'react'
import Layout from '../Layout'
import { Stack, Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { config } from '../../../config'
import { ppcType } from '../../../interfaces/PPC';
import PPCService from './api/PPC.api'
import PPCDataTable from '../../../components/Dashboard/PPC/PPCDataTable'
import LLineChart from '../../../components/Dashboard/PPC/Charts/LineChart'
import BBarChart from '../../../components/Dashboard/PPC/Charts/BarChart'
import SBarChart from '../../../components/Dashboard/PPC/Charts/SBarChart'
import PPieChart from '../../../components/Dashboard/PPC/Charts/PPieChart'
import SScatterPlot from '../../../components/Dashboard/PPC/Charts/SScatterPlot'
import AAreaChart from '../../../components/Dashboard/PPC/Charts/AAreaChart'
import BBubbleChart from '../../../components/Dashboard/PPC/Charts/BBubbleChart'
import KKPIMetrics from '../../../components/Dashboard/PPC/Charts/KKPIMetrics'
import Chart01 from '../../../components/Dashboard/PPC/Charts/Chart01'
import Grid from "@mui/material/Unstable_Grid2";

const PPC: React.FC = () => {

  const [ppcData, setPPCData] = React.useState<ppcType[]>([]);

  React.useEffect(() => {
    fetchPPCFromDB();
    // fetchPPCDemoData();
  }, [])

  const fetchPPCFromDB = async () => {
    let ppcFromDb = await PPCService.getPPCDataFromDB();
    if (ppcFromDb.data) setPPCData(ppcFromDb.data);
  }

  // const fetchPPCDemoData = async () => {
  //   let ppc = await PPCService.getPPCData();
  //   if (ppc) setPPCData(ppc);
  // }

  return (
    <PageContainer title={'PPC | Dashboard | ' + config.site.name} description='This is PPC page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Typography variant="h4">PPC</Typography>
          <PPCDataTable ppcData={ppcData} sx={{ height: '100%' }} />
          <Grid container spacing={3}>
            <Grid lg={12} sm={6} xs={12}>
              <LLineChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <BBarChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <SBarChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <PPieChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <SScatterPlot ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <AAreaChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <BBubbleChart ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <KKPIMetrics ppcData={ppcData} />
            </Grid>
            <Grid lg={12} sm={6} xs={12}>
              <Chart01 ppcData={ppcData} />
            </Grid>
          </Grid>
        </Stack>
      </Layout>
    </PageContainer>
  )
}

export default PPC