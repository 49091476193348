import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const getAllProducts = async () => {
    const response = await CustomFetch(API_URL + '/products', { method: 'GET' });
    return response.json();
}

const createProduct = async ( 
    SellerSKU: string, ASIN: string, 
    FnSKU: string, LastUpdated: string, 
    ProductName: string, TotalQuantity: number, 
    Marketplace: string, Condition: string
) => {
    const response = await CustomFetch(API_URL + '/products', {
        method: 'POST',
        body: JSON.stringify({
            SellerSKU: SellerSKU,
            ASIN: ASIN,
            FnSKU: FnSKU,
            LastUpdated: LastUpdated,
            ProductName: ProductName,
            TotalQuantity: TotalQuantity,
            Marketplace: Marketplace,
            Condition: Condition,
        })
    })
    return response.json();
}

const UpdateProductCost = async (sku:string, cost: number) => {
    const response = await CustomFetch(API_URL + `/products/${sku}/cost`, {
        method: 'PUT',
        body: JSON.stringify({
            cost
        })
    })
    return response.json();
}

const ProductService = {
    getAllProducts,
    createProduct,
    UpdateProductCost,
}

export default ProductService;