import { jwtDecode, JwtPayload } from "jwt-decode";
import { toast } from "react-toastify";

export function isAuthenticated() {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
        try {
            const decodedToken = jwtDecode(userToken);
            const currentTime = Date.now() / 1000; // Get current time in seconds
            // Check if token is expired
            if (decodedToken.exp && decodedToken.exp < currentTime) {
                // Token is expired, remove from localStorage
                localStorage.removeItem('userToken');
                toast.warn('Session Expired!! Login again');
                window.location.reload();
                return false; // Not authenticated
            }
            return true; // Token is valid, user is authenticated
        } catch (error) {
            // Handle token decode error (e.g., invalid token)
            localStorage.removeItem('userToken');
            toast.warning('Session Expired! Log-in again');
            window.location.reload();
            return false; // Not authenticated
        }
    }
    return false; // No token or user, not authenticated
}

interface CustomJwtPayload extends JwtPayload {
    organization: string; // Replace with actual type
    role: string;    // Replace with actual type
}

export function isSuperAdmin() {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
        try {
            const decodedToken = jwtDecode<CustomJwtPayload>(userToken);
            if ((decodedToken.role) !== '0') return false
            return true; // Role = 0, user is authorized as SuperAdmin
        } catch (error) {
            // Handle token decode error (e.g., invalid token)
            localStorage.removeItem('userToken');
            return false; // Not authorized as SuperAdmin
        }
    }
    return false; // No token or user, not authorized
}