import React from 'react'
import Layout from '../Layout'
import { Typography } from '@mui/material'

const PurchaseOrders: React.FC = () => {
  return (
    <Layout>
      <Typography variant="h4">PurchaseOrders</Typography>
    </Layout>
  )
}

export default PurchaseOrders