import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

interface Data {
  title: string
  description: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

export const advantageData: Data[] = [
  {
    title: 'Robust Customer Support',
    description: 'Dedicated 24/7 customer support via live chat, email, and phone, ensuring any issues are addressed promptly.',
    Icon: SupportAgentIcon,
  },
  {
    title: 'Dedicated Account Manager',
    description: 'You will get a dedicated account manager to help you with everything related to our products & services.',
    Icon: PersonOutlinedIcon,
  },
  {
    title: 'Scalability and Flexibility',
    description: 'Designed to grow with your business, offering features and performance that scale as your operations expand. Flexible pricing models to suit businesses of all sizes.',
    Icon: SupportAgentIcon,
  },
  {
    title: 'Multi-Channel Integration',
    description: 'Seamlessly manage and sync orders, and P&L data across multiple sales channels. Connect with popular platforms like Amazon, eBay, Shopify, WooCommerce, and more.',
    Icon: IntegrationInstructionsOutlinedIcon,
  },
]
