import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { tiersMonthly, tiersYearly } from './tiers';
import { Tab, Tabs } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function HomePricing() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box id="#pricing" sx={{ py: 14, backgroundColor: 'background.paper' }}>
            <Container>
                <Grid item >
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: { xs: 36, md: 46 },
                            lineHeight: 1,
                            fontWeight: 'bold',
                        }}
                    >
                        Pricing
                    </Typography>
                    <Box >
                        <Tabs value={value} onChange={handleChange} aria-label="PricingOption">
                            <Tab label="Annually" {...a11yProps(0)} />
                            <Tab label="Monthly" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Grid>
            </Container>
            <CustomTabPanel value={value} index={1}>
                <Container
                    id="pricing"
                    sx={{
                        pt: { xs: 4, sm: 12 },
                        // pb: { xs: 8, sm: 16 },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: { xs: 3, sm: 6 },
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
                    >
                        {tiersMonthly.map((tier) => (
                            <Grid
                                xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}
                                key={tier.title}
                            >
                                <Card
                                    sx={[
                                        {
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 4,
                                        },
                                        tier.title === 'Professional' &&
                                        ((theme) => ({
                                            border: 'none',
                                            background:
                                                'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                                            boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                                            ...theme.applyStyles('dark', {
                                                background:
                                                    'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                                                boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                                            }),
                                        })),
                                    ]}
                                >
                                    <CardContent>
                                        <Box
                                            sx={[
                                                {
                                                    mb: 1,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                },
                                                tier.title === 'Professional' ? { color: 'grey.100' } : { color: '' },
                                            ]}
                                        >
                                            <Typography component="h3" variant="h6">
                                                {tier.title}
                                            </Typography>
                                            {tier.title === 'Professional' && (
                                                <Chip color='primary' icon={<AutoAwesomeIcon />} label={tier.subheader} />
                                            )}
                                        </Box>
                                        <Box
                                            sx={[
                                                { display: 'flex', alignItems: 'baseline', },
                                                tier.title === 'Professional' ? { color: 'grey.50' } : { color: null },
                                            ]}
                                        >
                                            <Typography component="h3" variant="h2">
                                                {tier.price}
                                            </Typography>
                                            <Typography component="h3" variant="h6">
                                                &nbsp; per month
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                                        {tier.description.map((line) => (
                                            <Box
                                                key={line}
                                                sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                                            >
                                                <CheckCircleRoundedIcon
                                                    sx={[
                                                        { width: 20, },
                                                        tier.title === 'Professional' ? { color: 'primary.light' } : { color: 'primary.main' },
                                                    ]}
                                                />
                                                <Typography
                                                    variant="subtitle2"
                                                    component={'span'}
                                                    sx={[tier.title === 'Professional' ? { color: 'grey.50' } : { color: null },]}
                                                >
                                                    {line}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            fullWidth
                                            variant={tier.buttonVariant as 'outlined' | 'contained'}
                                            color={tier.buttonColor as 'primary' | 'secondary'}
                                        >
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={0}>
                <Container
                    id="pricing"
                    sx={{
                        pt: { xs: 4, sm: 12 },
                        // pb: { xs: 8, sm: 16 },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: { xs: 3, sm: 6 },
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
                    >
                        {tiersYearly.map((tier) => (
                            <Grid
                                xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}
                                key={tier.title}
                            >
                                <Card
                                    sx={[
                                        {
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 4,
                                        },
                                        tier.title === 'Professional' &&
                                        ((theme) => ({
                                            border: 'none',
                                            background:
                                                'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                                            boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                                            ...theme.applyStyles('dark', {
                                                background:
                                                    'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                                                boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                                            }),
                                        })),
                                    ]}
                                >
                                    <CardContent>
                                        <Box
                                            sx={[
                                                {
                                                    mb: 1,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                },
                                                tier.title === 'Professional' ? { color: 'grey.100' } : { color: '' },
                                            ]}
                                        >
                                            <Typography component="h3" variant="h6">
                                                {tier.title}
                                            </Typography>
                                            {tier.title === 'Professional' && (
                                                <Chip color='primary' icon={<AutoAwesomeIcon />} label={tier.subheader} />
                                            )}
                                        </Box>
                                        <Box
                                            sx={[
                                                { display: 'flex', alignItems: 'baseline', },
                                                tier.title === 'Professional' ? { color: 'grey.50' } : { color: null },
                                            ]}
                                        >
                                            <Typography component="h3" variant="h2">
                                                {tier.price}
                                            </Typography>
                                            <Typography component="h3" variant="h6">
                                                &nbsp; per month
                                            </Typography>
                                        </Box>
                                        <Typography component='h4' variant='h6' color={'secondary.light'} >{tier.caption}</Typography>
                                        <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                                        {tier.description.map((line) => (
                                            <Box
                                                key={line}
                                                sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                                            >
                                                <CheckCircleRoundedIcon
                                                    sx={[
                                                        { width: 20, },
                                                        tier.title === 'Professional' ? { color: 'primary.light' } : { color: 'primary.main' },
                                                    ]}
                                                />
                                                <Typography
                                                    variant="subtitle2"
                                                    component={'span'}
                                                    sx={[tier.title === 'Professional' ? { color: 'grey.50' } : { color: null },]}
                                                >
                                                    {line}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            fullWidth
                                            variant={tier.buttonVariant as 'outlined' | 'contained'}
                                            color={tier.buttonColor as 'primary' | 'secondary'}
                                        >
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </CustomTabPanel>
        </Box>
    );
}
