
interface FAQData {
  question: string
  answer: string
}

export const data: FAQData[] = [
  {
    question: 'How can Virtacc help in my marketplace e-commerce business?',
    answer: 'Aside a host of valuable features, Virtacc primarily automates the financial accounting, reconciliation and inventory management of your e-commerce business.',
  },
  {
    question: 'Which marketplace channels are supported by virtacc?',
    answer: 'Virtacc is currently integrated with all major Indian marketplace channels including Amazon, Flipkart, Shopify etc.',
  },
  {
    question: 'Is my data safe and secure with Virtacc?',
    answer: `Virtacc is hosted on the World's safest cloud servers - AWS. Virtacc uses bank level encryption when data interchange happens through API bridges.`,
  },
  {
    question: 'Do I need to make any payment or give my credit card details during the Demo period?',
    answer: 'Absolutely not. The free trial period of 30 days entitles you to a full version trial of Virtacc with no credit card details or payments to be made.',
  },
  {
    question: 'Does Amazon Have a Software for Sellers?',
    answer: 'Amazon has an app for sellers called Amazon Seller Central. While it provides some very useful reports like the Search Terms Report and other metrics for your store, it doesn’t cover everything you need to run a successful Amazon business. That’s where Virtacc comes into the picture.'
  },
  {
    question: 'What Is an Amazon Seller Platform?',
    answer: 'An Amazon seller platform is a third-party SaaS solution that enables sellers to identify insights and manage their Amazon business in a way that’s not available to people simply using Amazon Seller Central. The best Amazon seller platform gives entrepreneurs brand-new ways to optimize their product listings, advertising strategies, and branding efforts through a data-driven approach.'
  }
]