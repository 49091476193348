import React, { useState } from 'react';
import Layout from '../Layout';
import { Card, CardContent, Stack, Typography, TextField, Button } from '@mui/material';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { data } from './data';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';

const Reports: React.FC = () => {
  const [selectedReport, setSelectedReport] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const handleReportClick = (index: number) => {
    setSelectedReport(index);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    // Process the report with the provided inputValue here
    console.log(`Processing report ${selectedReport} with info: ${inputValue}`);
    // After processing, you can reset the selectedReport if needed
    setSelectedReport(null);
    setInputValue('');
  };

  return (
    <PageContainer title={"Reports | Dashboard | " + config.site.name} description='This is Overview page of Virtacc'>
      <Layout>
        <Typography lineHeight={2} variant="h4">Reports</Typography>
        <Stack spacing={1}>
          {data.map(({ title, description, link }, index) => (
            selectedReport === index ? (
              <Grid2 key={index} sx={{ width: '100%' }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant="h5">{title}</Typography>
                      <TextField
                        label="Enter information to process the report"
                        value={inputValue}
                        onChange={handleInputChange}
                        fullWidth
                      />
                      <Button variant="contained" onClick={handleSubmit}>Process Report</Button>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangeCalendar disableFuture />
                      </LocalizationProvider>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid2>
            ) : (
              selectedReport === null && (
                <Grid2 key={index} sx={{ width: '100%' }}>
                  <Card sx={{ height: '100%' }} onClick={() => handleReportClick(index)}>
                    <CardContent>
                      <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Stack spacing={1}>
                          <Typography variant="h5">{title}</Typography>
                          <Typography color={'text.secondary'}>{description}</Typography>
                        </Stack>
                        <ArrowForwardIosIcon />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid2>
              )
            )
          ))}
        </Stack>
      </Layout>
    </PageContainer>
  );
};

export default Reports;
