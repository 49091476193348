import { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import NewHeader from './Header/NewHeader'

interface Props {
    children: ReactNode
}

const MainLayout: FC<Props> = ({ children }) => {
    return (
        <Box component="main">
            <Header />
            {/* <NewHeader /> */}
            {/* <Box sx={{ mt: 15 }}></Box> */}
            {children}
            <Footer />
        </Box>
    )
}

export default MainLayout
