import { Navigate } from "react-router-dom";
import { ReactElement } from "react";
import { isAuthenticated, isSuperAdmin } from "../../utils/Auth/Auth";
import { paths } from "../../paths";

interface ProtectedRouteSuperAdminProps {
  children: ReactElement;
}

export const ProtectedRouteSuperAdmin = ( {children} : ProtectedRouteSuperAdminProps) => {
  if (!isAuthenticated()) {
    return <Navigate to={paths.auth.signIn} />
  }
  if (!isSuperAdmin()) {
    // user is not SuperAdmin
    return <Navigate to={paths.dashboard.overview} />;
  }
  return children;
};