export const paths = {
	home: '/',
	main: {
		support: '/support'
	},
	auth: {
		signIn: '/auth/sign-in',
		signUp: '/auth/sign-up',
		resetPassword: '/auth/reset-password',
		verify_amazon_auth: '/auth/verify/amazon-auth-code',
		verify_amazon_ads_auth: '/auth/verify/amazon-ads-auth-code',
		amazon_seller: 'amazon_seller',
	},
	dashboard: {
		overview: '/dashboard',
		products: '/dashboard/products',
		orders: '/dashboard/orders',
		charts: '/dashboard/charts',
		expenses: '/dashboard/expenses',
		reports: '/dashboard/reports',
		ppc: {
			dashboard: '/dashboard/ppc',
			recommendations: '/dashboard/ppc/recommendations',
			autoLog: '/dashboard/ppc/log'
		},
		inventory: {
			planner: '/dashboard/inventory',
			purchaseOrders: '/dashboard/inventory/purchaseOrders',
			fbaShipments: '/dashboard/inventory/fbaShipments',
			suppliers: '/dashboard/inventory/suppliers',
		},
		autoresponder: {
			campaigns: '/dashboard/autoresponder/campaigns',
			products: '/dashboard/autoresponder/products',
			orders: '/dashboard/autoresponder/orders',
		},
		moneyback: {
			lostAndDamaged: '/dashboard/moneyback/lostAndDamaged',
			returns: '/dashboard/moneyback/returns',
			fbaFeeChanges: '/dashboard/moneyback/fbaFeeChanges',
		},
		alerts: {
			dashboard: '/dashboard/alerts',
			settings: '/dashboard/alerts/settings',
		},
		settings: {
			general: '/dashboard/settings',
			users: '/dashboard/settings/users',
			integrations: '/dashboard/settings/integrations',
			automation: '/dashboard/settings/automation',
			tellAFriend: '/dashboard/settings/tellAFriend',
			billing: '/dashboard/billing',
		},
		account: '/dashboard/account',
		customers: '/dashboard/customers',
		integrations: '/dashboard/integrations',
		users: '/dashboard/users',
		addUser: '/dashboard/add-user',
		upload: '/dashboard/upload',
	},
	organization: {
		overview: '/organizations',
		subscriptions: '/organizations/subscriptions',
		addUser: '/organizations/add-user',
		account: '/organizations/account',
		customers: '/organizations/customers',
		settings: '/organizations/settings'
	},
	errors: {
		notFound: '/404'
	},
	legals: {
		termsOfService: '/terms-of-service',
		privacyPolicy: '/privacy-policy'
	},
	practice: {
		home: '/practice',
		marketing: '/practice/marketing',
		blog: '/practice/blog'
	},
	allElse: '*'
} as const;
