'use client';

import * as React from 'react';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';

const ContactUsForm: React.FC = () => {

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <Typography variant='h5' sx={{ ml: 2, mt: 2 }}> Share your ideas </Typography>
            <Grid container spacing={3} sx={{ p: 2 }}>
                <Grid md={6} xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel>Full name</InputLabel>
                        <OutlinedInput label="Full name" name="fullName" />
                    </FormControl>
                </Grid>
                <Grid md={6} xs={12}>
                    <FormControl fullWidth required>
                        <InputLabel>Email address</InputLabel>
                        <OutlinedInput label="Email address" name="email" />
                    </FormControl>
                </Grid>
                <Grid md={12} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Message</InputLabel>
                        <OutlinedInput multiline rows={5} label="Message" name="message" type="text" />
                    </FormControl>
                </Grid>
            </Grid>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button type="submit" variant="contained">Send Inquiry</Button>
            </CardActions>
        </form>
    );
}

export default ContactUsForm;