import { AdsTokenObjType } from "../../../../interfaces/Amazon/TokenObj";
import { CustomFetch } from "../../../../utils/Fetch/Fetch";

const API_URL = process.env.REACT_APP_API_URL;

const retrieveAmazonAdAPITokens = async (code: string | null) => {
    if (code === null) {
        return { "error": "auth code not available" }
    }
    let grantType = 'authorization_code';
    let authCode = code;
    let redirectUri = 'https://virtacc.com/auth/verify/amazon-ads-auth-code';
    let clientId = 'amzn1.application-oa2-client.30f67e0177d9403495a4c63c65de2549';
    let clientSecret = 'amzn1.oa2-cs.v1.16b07c129845c1189a58ae9d14ac1e7947453ce62d384b13fd74742eb38e93f7';
    const response = await fetch(
        `https://api.amazon.co.uk/auth/o2/token?grant_type=${grantType}&code=${authCode}&redirect_uri=${redirectUri}&client_id=${clientId}&client_secret=${clientSecret}`,
        {
            method: 'POST',
            body: JSON.stringify({
                grant_type: grantType,
                code: authCode,
                redirect_uri: redirectUri,
                client_id: clientId,
                client_secret: clientSecret,
            }),
            headers: {
                "content-type": "application/json"
            }
        }
    )
    let responseData = await response.json();
    // await sendARTokenToServer(responseData);
    console.log('ads response', responseData)
    return responseData;
}

const sendARTokenToServer = async (responseData: AdsTokenObjType) => {
    const response = await CustomFetch(API_URL + '/ads/auth/verify', {
        method: 'POST',
        body: JSON.stringify(responseData),
    });
    let r = await response.json();
}

const retrieveAmazonSPAPITokens = async (code:string | null) => {
    if (!code) return { "error": "auth code not available" };
    let grantType = 'authorization_code';
    let authCode = code;
    let redirectUri = 'https://virtacc.com/auth/verify/amazon-auth-code';
    let clientId = 'amzn1.application-oa2-client.f5e3419a807f40429263f784a03b2a56';
    let clientSecret = 'amzn1.oa2-cs.v1.6563d0e17068bd1dffd992c82c1a96e5807b6c7753c7ac1b424135ee12c8282e';
    const response = await fetch(
        `https://api.amazon.co.uk/auth/o2/token?grant_type=${grantType}&code=${authCode}&redirect_uri=${redirectUri}&client_id=${clientId}&client_secret=${clientSecret}`,
        {
            method: 'POST',
            body: JSON.stringify({
                grant_type: grantType,
                code: authCode,
                redirect_uri: redirectUri,
                client_id: clientId,
                client_secret: clientSecret,
            }),
            headers: {
                "content-type": "application/json"
            }
        }
    )
    let responseData = await response.json();
    console.log('sp api response', responseData)
    // await sendARTokenToServer(responseData);
    return responseData;
}

const verifyService = {
    retrieveAmazonAdAPITokens,
    retrieveAmazonSPAPITokens
}

export default verifyService;