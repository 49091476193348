
// Purpose: Similar to the line chart but with filled area under the line. Good for showing volume or cumulative metrics over time.
// Data: Plot date on the x-axis and cumulative spends or clicks on the y-axis.
// Chart Type: Area Chart
// Example: Show the cumulative spends over time.

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ppcType } from '../../../../interfaces/PPC';
import dayjs from 'dayjs'; // Import dayjs for date formatting

// Define the props that the component will receive
interface AAreaChartProps {
    ppcData: ppcType[]; // Array of ppcType data to pass to the chart
}

const AAreaChart: React.FC<AAreaChartProps> = ({ ppcData }) => {
    const [chartData, setChartData] = React.useState<{ date: string; spend: number }[]>([]);
    // Check if ppcData is received and process it
    React.useEffect(() => {
        if (ppcData && ppcData.length > 0) {
            const aggregatedData = ppcData.reduce((acc, item) => {
                const formattedDate = dayjs(item.date).format('YYYY-MM-DD'); // Format date as "YYYY-MM-DD"

                const existing = acc.find((data) => data.date === formattedDate);
                if (existing) {
                    // Accumulate the values for existing dates
                    existing.spend += item.spend;
                } else {
                    // Create a new entry for the formatted date
                    acc.push({
                        date: formattedDate,
                        spend: item.spend,
                    });
                }
                return acc;
            }, [] as { date: string; spend: number }[]);

            setChartData(aggregatedData); // Update the state with the aggregated data
        }
    }, [ppcData]); // This useEffect will run whenever ppcData changes

    // If no data, return a placeholder message or render nothing
    if (chartData.length === 0) {
        return <div>No data available for the chart.</div>;
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="spend" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AAreaChart;