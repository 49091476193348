import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Layout from '../Layout';
import PageContainer from '../../../components/PageContainer/PageContainer';
import { config } from '../../../config';

const AccountOrganization: React.FC = () => {
  return (
    <PageContainer title={'Account | Organization | ' + config.site.name} description='This is Account page of Virtacc'>
      <Layout>
        <Stack spacing={3}>
          <Typography variant="h4">Account</Typography>
        </Stack>
      </Layout>
    </PageContainer>
  );
}

export default AccountOrganization;
