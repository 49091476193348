'use client';

import * as React from 'react';
import { Avatar, Badge, Box, IconButton, Stack, Tooltip } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { usePopOver } from '../../../utils/PopOver/usePopOver';

import UserPopOver from './UserPopOver';
import MobileNav from './MobileNav';
import SimpleDialog from './SearchDialog';

const MainNav: React.FC = () => {
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const [openSearch, setOpenSearch] = React.useState<boolean>(false);

  const userPopover = usePopOver<HTMLDivElement>();

  const handleOpenSearch = (): void => setOpenSearch(true);
  const handleCloseSearch = (): void => setOpenSearch(false);

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          borderBottom: '1px solid var(--mui-palette-divider)',
          backgroundColor: 'var(--mui-palette-background-paper)',
          position: 'sticky',
          top: 0,
          zIndex: 'var(--mui-zIndex-appBar)',
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{ alignItems: 'center', justifyContent: 'space-between', minHeight: '64px', px: 2 }}
        >
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
            <IconButton
              onClick={(): void => {
                setOpenNav(true);
              }}
              sx={{ display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Tooltip title="Search">
              <IconButton onClick={handleOpenSearch} >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {
              openSearch && 
              ( <SimpleDialog
                selectedValue={'selectedValue'}
                open={openSearch}
                onClose={handleCloseSearch}
              /> )
            }
          </Stack>
          <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
            <Tooltip title="Contacts">
              <IconButton>
                <PeopleOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <Badge badgeContent={4} color="success" variant="dot">
                <IconButton>
                  <NotificationsNoneIcon />
                </IconButton>
              </Badge>
            </Tooltip>
            <Avatar
              onClick={userPopover.handleOpen}
              ref={userPopover.anchorRef}
              src="/assets/images/avatars/avatar_1.jpg"
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
        </Stack>
      </Box>
      <UserPopOver anchorEl={userPopover.anchorRef.current} onClose={userPopover.handleClose} open={userPopover.open} />
      <MobileNav
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
      />
    </React.Fragment>
  );
}

export default MainNav;