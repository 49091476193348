import React from 'react';
import { useLocation } from 'react-router-dom';
import verifyService from './api/VerifyApi';
import { paths } from '../../../paths';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const VerifyAmazonAdsAuthCode: React.FC = () => {
  const navigate = useNavigate();
  const handle = async (code: string | null, scope: string | null) => {
    if (!code) return; // Handle case where code is null
    try {
      let tokenObj = await verifyService.retrieveAmazonAdAPITokens(code);
      if (tokenObj['error']) {
        navigate(paths.dashboard.overview, { replace: true });
        toast.error(`Ads integration failed: ${tokenObj['error']}`)
      } else {
        navigate(paths.dashboard.overview, { replace: true });
        toast.success('Ads API integrated successfully');
      }
    } catch (error) {
      toast.error(`Something went wrong: ${error}`)
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const amazon_ads_auth_code = queryParams.get('code');
  const amazon_ads_scope = queryParams.get('scope');

  React.useEffect(() => {
    handle(amazon_ads_auth_code, amazon_ads_scope);
  })

  return (
    <></>
  );
};

export default VerifyAmazonAdsAuthCode;