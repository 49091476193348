// import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from './routes/Routes';
import ThemeProvider from './styles/theme/theme-provider';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  return (
    <ThemeProvider >
      <ToastContainer />
      <AppRoutes />
      <ScrollToTop />
    </ThemeProvider>
  );
}

export default App;
