import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  ChipProps,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow
} from '@mui/material';
import type { SxProps } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import { OrganizationsData } from '../../../interfaces/Organization';
import organizationService from '../../../templates/Organizations/api/Organization.api';

export interface OrganizationProps {
  sx?: SxProps;
  organizations?: OrganizationsData[];
}

const statusMap: Record<string, { label: string, color: ChipProps['color'] }> = {
  true: { label: 'Active', color: 'success' },
  false: { label: 'Inactive', color: 'error' },
};

const AllOrganizations: React.FC<OrganizationProps> = ({ organizations = [], sx }) => {

  const handleClickStatus = async (organization: string) => {
    await organizationService.enableDisableOrganization(organization);
    window.location.reload();
  }

  return (
    <Card sx={sx}>
      <CardHeader title="Organizations" />
      <Divider />
      <Box sx={{ overflowX: 'auto' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Phone</TableCell>
              {/* <TableCell sortDirection="desc">Date</TableCell> */}
              <TableCell>Email</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => {
              //   const { label, color } = statusMap[order.status] ?? { label: 'Unknown', color: 'default' };
              const { label, color } = statusMap[organization.isActive.toString()] ?? { label: 'Unknown', color: 'default' };

              return (
                <TableRow hover key={organization.organization}>
                  <TableCell>{organization.name}</TableCell>
                  <TableCell>{organization.owner}</TableCell>
                  <TableCell>{organization.phone}</TableCell>
                  <TableCell>{organization.email}</TableCell>
                  <TableCell>{dayjs(organization.createdAt).format('MMM D, YYYY')}</TableCell>
                  <TableCell>
                    <Chip 
                      onClick={() => handleClickStatus(organization.organization)} 
                      color={color} label={label}
                      variant="outlined"
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={<ArrowForwardIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
      {/* <TablePagination 
        component="div"
        count={count}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
}

export default AllOrganizations;